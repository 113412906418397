
import { ref, defineComponent } from 'vue';
import CreditTotalFormInfo from '@/modules/credit/components/credit-total-form-info.vue';
import HttpRequest from '@/util/axios.config';
import { format } from '@/methods/date';

interface GetRecordListInCreditByMergeOffice{
    availableNum: string;
    list: Array<{
        changeNum: string;
        remainNum: string;
        time: string;
        uuid: string;
    }>;
    total: number;
    totalNum: string;
    usedNum: string;
}

export default defineComponent({
    components: {
        CreditTotalFormInfo
    },
    setup(props) {
        const isVisible = ref(true);
        const creditList = ref<GetRecordListInCreditByMergeOffice['list']>([]);
        const creditListTotal = ref(0);
        const creditNumInfo = ref({
            totalNum: '',
            usedNum: '',
            availableNum: ''
        });

        function searchCreditInfo(params: {timeStart: string;timeEnd: string;page: number;row: number}) {
            const queryParams = { ...params };
            queryParams.timeStart = format(queryParams.timeStart, 'YYYY-MM-DD');
            queryParams.timeEnd = format(queryParams.timeEnd, 'YYYY-MM-DD');
            HttpRequest.get('v4/web/office/credit/pm/getRecordList', [{ ...queryParams }, false],
                ((res: {data: GetRecordListInCreditByMergeOffice}) => {
                    creditList.value = res.data.list;
                    creditListTotal.value = res.data.total;
                    creditNumInfo.value = {
                        totalNum: res.data.totalNum,
                        usedNum: res.data.usedNum,
                        availableNum: res.data.availableNum
                    };
                }));
        }
        searchCreditInfo({
            timeStart: '', timeEnd: '', page: 1, row: 10
        });

        return {
            isVisible,
            creditList,
            creditListTotal,
            creditNumInfo,
            searchCreditInfo,
            props
        };
    }
});
