
import {
    PropType, reactive, watch, defineComponent
} from 'vue';

export default defineComponent({
    props: {
        creditList: {
            type: Array,
            default: () => ([]),
            require: true
        },
        creditListTotal: {
            type: Number,
            default: 0,
            require: true
        },
        creditNumInfo: {
            type: Object as PropType<{totalNum: string;usedNum: string;availableNum: string}>,
            default: () => ({ totalNum: '', usedNum: '', availableNum: '' }),
            require: true
        },
        infoQueryParams: {
            type: Object as PropType< {
                timeStart: string;
                timeEnd: string;
                page: number;
                row: number;
            }>,
            default: () => ({
                timeStart: '',
                timeEnd: '',
                page: 1,
                row: 10
            })
        }
    },
    emits: ['search'],
    setup(props, { emit }) {
        const formData = reactive({
            timeStart: '',
            timeEnd: '',
            page: 1,
            row: 10
        });

        const column = [
            {
                prop: 'time',
                label: WordList.ProperAllTextTime
            },
            {
                prop: 'changeNum',
                label: WordList.CreditAmount
            },
            {
                prop: 'remainNum',
                label: WordList.Remaining
            }
        ];

        watch(() => props.infoQueryParams, (newVal) => {
            Object.keys(formData).forEach((key) => {
                // @ts-ignore
                formData[key] = newVal[key];
            });
        }, { deep: true });

        function searchHandler() {
            emit('search', formData);
        }

        return {
            formData,
            column,
            searchHandler,
            props
        };
    }
});
