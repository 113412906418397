import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_credit_total_form_info = _resolveComponent("credit-total-form-info")
  const _component_custom_dialog = _resolveComponent("custom-dialog")

  return (_openBlock(), _createBlock(_component_custom_dialog, {
    modelValue: _ctx.isVisible,
    title: _ctx.$wordList.CreditLogs,
    width: "1000px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_credit_total_form_info, {
        creditList: _ctx.creditList,
        creditListTotal: _ctx.creditListTotal,
        creditNumInfo: _ctx.creditNumInfo,
        onSearch: _ctx.searchCreditInfo
      }, null, 8, ["creditList", "creditListTotal", "creditNumInfo", "onSearch"])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}